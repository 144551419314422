<template>
  <div class="count">{{getTime}}</div>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    count: {
      type: Number,
      default: 30
    }
  },
  data() {
    return {
      timeId: null,
      time: 0
    }
  },
  mounted() {
    this.time = this.count
    this.startCountDown()
  },
  destroyed() {
    clearInterval(this.timeId)
  },
  computed: {
    getTime() {
      return this.time || 0
    }
  },
  methods: {
    startCountDown() {
      this.timeId = setInterval(() => {
        if(this.time > 0){
          this.time = this.time - 1
        } else {
          this.$emit('onStop')
          clearInterval(this.timeId)
        }
      },1000)
    }
  }
}
</script>

<style lang="less" scoped>
.count{
  text-align: center;
  font-size: 30px;
  width: 120px;
  height: 120px;
  margin: 20px auto;
  border-radius: 50%;
  text-align: center;
  background-color: #e3f2fd;
  line-height: 120px;
  color: #2296f3;
}
</style>